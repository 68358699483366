<template>
    <div class="flex flex-wrap justify-between">
        <div v-if="!loading && !loadingStudents && !loadingPage">
            <div>
                <a-button @click="$router.push({ name: 'final_deliveries' })" class="w-full md:w-auto">
                    Regresar
                </a-button>
            </div>
            <h3 class="text-2xl my-4">Entregas finales</h3>
            <div v-if="finalDeliveries" class="flex justify-between mb-4">
                <div>
                    <p>Especialidad: <b>{{ finalDeliveries.specialization.title }}</b></p>
                </div>
                <div>
                    <p>Semestre: <b>{{ finalDeliveries.semester.number }}</b></p>
                </div>
                <div>
                    <p>Materia: <b>{{ finalDeliveries.course.title }}</b></p>
                </div>
                <div>
                    <p>Sección: <b>{{ finalDeliveries.section.letter_code }}</b></p>
                </div>
                <div>
                    <p>Fecha: <b>{{ mappedDate }}</b></p>
                </div>
            </div>
            <!--            <div class="block m-2">-->
            <!--                <p>Jurados: <span v-for="jury in finalDeliveries.juries" :key="jury" class="inline-block m-1"><a-badge>{{ jury.full_name }}</a-badge></span></p>-->
            <!--            </div>-->
            <a-table
                :columns="columns"
                :source="mappedValues">
<!--                <template #td(name)="{ item }">-->
<!--                    {{ item.name }}-->
<!--                </template>-->
                <template #td(note)="{ item }">
                  <span v-if="item.note === '0.00'" class="text-red-400" title="No entregό">
                    {{ 'N/E' }}
                  </span>
                  <span v-else>
                    {{ `${item.note !== null ? item.note : '-'}` }}
                  </span>
                </template>
                <template #td(actions)="{ item }">
                    <a-dropdown>
                        <template #menu>
                            <a-dropdown-item @click="selectStudent(item)">
                                Agregar nota
                            </a-dropdown-item>
                        </template>
                    </a-dropdown>
                </template>
            </a-table>
        </div>
<!--        <template v-if="!loading">-->
<!--            <table class="w-full">-->
<!--                <thead class="text-left">-->
<!--                    <tr>-->
<!--                        <th>Nombre</th>-->
<!--                        <th>Nota</th>-->
<!--                        <th v-for="jury in mappedJuries" :key="jury.id">{{ jury.name }}</th>-->
<!--                    </tr>-->
<!--                </thead>-->
<!--                <tbody>-->
<!--                    <tr v-for="student in finalDeliveriesNotes" :key="student.id">-->
<!--                        <td>{{ student.last_name + ' ' + student.first_name }}</td>-->
<!--                        <td>{{ student.note ? student.note : '-' }}</td>-->
<!--                        &lt;!&ndash;                    <td v-for="jury in student.student_juries" :key="jury.id">{{ jury.jury.teacher.first_name + ': ' + jury.note }}</td>&ndash;&gt;-->
<!--                    </tr>-->
<!--                </tbody>-->
<!--            </table>-->
<!--        </template>-->
<!--        {{ mappedJuries }}-->
<!--        {{ mappedValues }}-->
        <!--        <template v-for="(jury, index) in finalDeliveriesNotes[0].student_juries">-->

        <!--        </template>-->


        <!--        <template #td(jurado_1)="{ item }">-->
        <!--            <div>qdqdq</div>-->
        <!--        </template>-->

        <!--template #td(jurado_2)="{ item  }">
            <template v-if="item.student_juries[1].jury.teacher.id === user.teacher.id">

            </template>
            <template v-else>
                -
            </template>
        </template>
        <template #td(attendance)="{ item  }">
            <a-switch v-model="item.attendance" :disabled="item.note > 0">
                <template #suffix>
                    Marcar inasistencia
                </template>
                </a-switch>
        </template>
        <template #td(delayed)="{ item  }">
            Enviar
        </template-->
        <a-modal
            :title="'Agregar Nota'"
            :show="modal"
            :disabled-confirm="note > 20 || note < 0 || (user.isAdmin && !selectedJury)"
            :loading="loadingPage"
            @confirm="evaluateFinalDelivery()"
            @cancel="modal = false"
        >
            <span v-if="note === null || note === ''" class="text-sm text-red-400">* Al no colocar nota el estudiante será evaluado como inasistente y su nota será 0 *</span>
            <a-input type="number" :min="0" :max="20" v-model="note"/>
            <a-select
                label="Jurado"
                required
                v-if="user.isAdmin"
                :disabled="loadingStudents"
                :source="mappedJuries ? mappedJuries.map($0 => ({ label: $0.name, value: $0.id })) : []"
                v-model="selectedJury" />
        </a-modal>
    </div>
</template>
<script>

import {mapActions, mapState, mapGetters} from 'vuex'
import moment from 'moment'

export default {
    data() {
        return {
            subject: '',
            date: null,
            modal: false,
            note: 20,
            selectedStudent: [],
            loadingPage: false,
            selectedJury: null,
        }
    },
    computed: {
        ...mapState({
            finalDeliveriesNotes: ({finalDeliveries}) => finalDeliveries.students,
            finalDeliveries: ({finalDeliveries}) => finalDeliveries.current,
            loading: ({finalDeliveries}) => finalDeliveries.loading,
            loadingStudents: ({students}) => students.loading,
            user: ({session}) => session.user,
        }),
        ...mapGetters({
            error: 'error'
        }),
        mappedJuries() {
          if (!this.loading) {
            return this.finalDeliveries?.juries.map($1 => {
              return {
                name: $1.full_name,
                id: $1.id
              }
            }).sort((a, b) => a.id - b.id)
          } else {
            return []
          }
        },
        mappedValues() {
          // console.log(this.finalDeliveriesNotes)
          if (!this.loading) {
            return this.finalDeliveriesNotes.map((student) => {
              return {
                name: student.last_name + ' ' + student.first_name,
                id: student.id,
                note: student.pivot.final_note ? student.pivot.final_note : null,
                final_delivery_id: student.pivot.final_delivery_id,
                juries: student.student_juries.map((jury) => {
                  return {
                    jury: jury.jury.teacher.full_name,
                    id: jury.jury.teacher.id,
                    note: jury.note
                  }
                }).sort((a, b) => a.id - b.id)
              }
            })
          } else {
            return []
          }
        },
        columns() {
            if (!this.loading) {
                let columns = [
                    {title: 'Nombre', key: 'name', display: true},
                    {title: 'Nota', slot: 'note', display: true},
                    //{ title: 'Comment', slot: 'note', display: true }
                ].filter($0 => $0.display)
                if (this.mappedJuries) {
                  this.mappedJuries.forEach(function (value, index) {
                    columns.push({
                      title: value.name,
                      slot: `jury_${index}`,
                      display: true,
                      mutate: ({key}) => {
                        // return key.juries.forEach((jury, index) => {
                        //     if (jury.id === key.juries[index].id) {
                        //         console.log(key.juries[index]?.note)
                        //         return key.juries[index]?.note
                        //     } else {
                        //         console.log('neun')
                        //     }
                        // })
                        return key.juries[index]?.note ? key.juries[index]?.note : '-'
                      }
                    })
                  })
                  columns.push({
                    title: 'Acciones',
                    slot: 'actions',
                    alignment: 'right',
                    columnFilterable: false,
                    display: this.user.isTeacherGroup || this.user.isAdmin
                  })
                  columns = columns.filter($0 => $0.display)
                  return columns
                } else {
                  return []
                }
            } else {
                return []
            }
        },
        mappedDate() {
            if (moment.utc(this.finalDeliveries.date).format('HH:mm') === '00:00') {
                return moment.utc(this.finalDeliveries.date).format('DD-MM-YYYY') + ' - Sin hora -'
            } else {
                return moment.utc(this.finalDeliveries.date).format('DD-MM-YYYY hh:mm a')
            }
        },

    },
    methods: {
        ...mapActions({
            fetchFinalDelivery: 'finalDeliveries/fetch',
            fetchStudents: 'finalDeliveries/fetchStudents',
        }),
        selectStudent(student) {
            this.modal = true
            this.selectedStudent = student
        },
        evaluateFinalDelivery() {
            const userId = this.user.isAdmin ? this.selectedJury : this.user.teacher.id

            this.$repository.finalDeliveries.juries(userId)
                .registerNote(this.selectedStudent.final_delivery_id, this.selectedStudent.id, {note: this.note ? this.note : 0})
                .then(() => {
                    this.$message.success({
                        title: '',
                        message: 'Se registró la nota con exito'
                    })
                    let params = {
                        id: this.$route.params.delivery_id,
                        filter: {}
                    }
                    this.loadingPage = true
                    this.fetchFinalDelivery(params)
                    this.fetchStudents(params)
                    // this.sortNotes()
                })
                .finally(() => {
                    this.modal = false
                    this.loadingPage = false
                    this.note = 20
                })
        },
        sortNotes() {
            this.finalDeliveriesNotes.sort(function (a, b) {
                if (a.last_name < b.last_name) {
                    return -1
                }
                if (a.last_name > b.last_name) {
                    return 1
                }
                return 0;
            })
        },
    },
    mounted() {
        // this.sortNotes()
    },
    created() {
        //this.columns.concat({ title: 'Accion', slot: 'attendance', display: true })
        let params = {
            id: this.$route.params.delivery_id,
            filter: {}
        }
        this.fetchFinalDelivery(params)
        this.fetchStudents(params)
    }
}
</script>
