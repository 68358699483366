<template>
    <div>
        <div class="mb-2">
            <a-button @click="$router.back()">
                Regresar
            </a-button>
        </div>
        <div class="flex">
            <div class="w-full">
                <form @submit.prevent="update({ ...delivery, date: date + ' ' + time, delivery_until_at:  date + ' ' + endTime, time: time })"
                      @keydown="removeError($event.target.id)">
                    <h3 class="my-4 text-2xl">Actualizar entrega final</h3>
                    <div class="flex flex-wrap -mx-2">
                        <div class="w-full md:w-1/2 lg:w-1/2">

                            <div class="flex flex-wrap w-full">
                                <a-select
                                    label="Salón de clase"
                                    class="flex-grow mb-4"
                                    id="classroom_id"
                                    :loading="loadingClassrooms"
                                    :source="classrooms.map(it => ({ label: it.code, value: it.id }))"
                                    :error="error('classroom_id', { attribute: 'aula de clase' })"
                                    v-model="delivery.classroom_id"/>
                            </div>

                            <a-input label="Fecha" class="mb-4" id="date" type="date" v-model="date"
                                   :error="error('date', { attribute: 'fecha', date: () => 'hoy' })"/>

                            <div class="flex flex-wrap w-full">
                              <div class="flex-1 mr-2">
                                <a-input label="Hora de entrada" class="mb-4" id="time" type="time" v-model="time"/>
                              </div>
                              <div class="flex-1 ml-2">
                                <a-input label="Hora de salida" class="mb-4 ml-2" id="delivery_until_at" type="time" v-model="endTime"
                                         :error="error('date', { attribute: 'Hora de salida', date: () => 'la hora de entrada'})"
                                />
                              </div>
                            </div>

                            <div class="flex flex-wrap w-full">
                                <a-select
                                    label="Jurados"
                                    class="flex-grow mb-4"
                                    id="juries"
                                    :loading="loadingTeachers"
                                    :source="mappedTeachers.map(it => ({ label: `${it.first_name} ${it.last_name}`, value: it.id }))"
                                    :error="error('juries', { attribute: 'profesor' })"
                                    v-model.lazy="jurySelection"/>
                            </div>

                            <div v-for="jury in mappedJury" :key="jury.id" class="inline-block">
                                <div class="inline-block m-2">
                                    <a-badge
                                        @close="removeJury(jury)"
                                        class="inline-block">
                                        {{ jury.full_name }}
                                    </a-badge>
                                </div>
                            </div>
                            <a-input
                                label="Resumen"
                                id="summary"
                                v-model="delivery.summary"/>
                        </div>
                    </div>
                    <div class="flex flex-wrap mt-4 space-x-2">
                        <a-button type="submit" :loading="loadingClassrooms"
                                  :disabled="loadingClassrooms || !date || !time || !endTime">
                            {{ isUpdating ? 'Actualizar cambios' : 'Guardar cambios' }}
                        </a-button>

                        <a-button outlined :to="{ name: 'final_deliveries' }">
                            Cancelar
                        </a-button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';
import moment from 'moment'

export default {
    data: () => ({
        delivery: {
            date: null,
            summary: '',
            classroom_id: null,
            juries: []
        },
        date: '',
        time: '',
        // endDate: '',
        endTime: '',
        jurySelection: [],
        isUpdating: false
    }),
    computed: {
        ...mapGetters({
            error: 'error'
        }),
        ...mapState({
            currentFinalDeliveryOnState: state => state.finalDeliveries.current,
            classrooms: state => state.classrooms.all,
            loadingClassrooms: state => state.classrooms.loading,
            user: state => state.session.user,
            teachers: state => state.teachers.all,
            loadingTeachers: state => state.teachers.loading
        }),
        mappedTeachers () {
            return this.teachers.filter(it => it.id !== this.course_section?.teacher_id)
        },
        mappedJury () {
            const list = []
            this.teachers.filter(it => {
                return this.delivery.juries.forEach($0 => {
                    if ($0 === it.id) {
                        return list.push(it)
                    }
                })
            })
            return list
        }
    },
    watch: {
        currentFinalDeliveryOnState(val) {
            if (val) {
                this.delivery = {
                    ...this.delivery,
                    ...val,
                    juries: val.juries.map(it => (it.id))
                }
                this.delivery.date = moment.utc(this.delivery.date).format('YYYY-MM-DD HH:mm')
                this.date = this.delivery.date ? moment.utc(this.delivery.date).format('YYYY-MM-DD') : null
                this.time = this.delivery.date ? moment.utc(this.delivery.date).format('HH:mm') : null
                // this.endDate = moment.utc(this.delivery.delivery_until_at).format('YYYY-MM-DD')
                this.endTime = this.delivery.delivery_until_at ? moment.utc(this.delivery.delivery_until_at).format('HH:mm') : null
            }
        },
        jurySelection(val) {
            if (val) {
                if (!this.delivery.juries.includes(val)) {
                    this.delivery.juries.push(val)
                    this.$repository.finalDeliveries.juries(val).attach(this.currentFinalDeliveryOnState.id)
                }
            }
        },
    },
    methods: {
        ...mapMutations({
            setClassrooms: 'classrooms/SET_CLASSHOURS'
        }),
        ...mapActions({
            update: 'finalDeliveries/update',
            removeError: 'removeError',
            fetchClassrooms: 'classrooms/index',
            fetchFinalDelivery: 'finalDeliveries/fetch',
            fetchTeachers: 'teachers/index'
        }),
        moment: () => moment(),
        removeJury(jury) {
            this.delivery.juries.splice(this.delivery.juries.indexOf(jury.id), 1)
            this.$repository.finalDeliveries.juries(jury.id).detach(this.currentFinalDeliveryOnState.id)
        }
    },
    mounted() {
        this.fetchTeachers({
            enabled: true,
            limit: 0
        })
    },
    created() {
        this.fetchFinalDelivery({
            id: this.$route.params.delivery_id,
            filters: {
                with: 'juries'
            }
        })

        this.fetchClassrooms({limit: 0, enabled: true})
    },
    destroyed() {
        this.setClassrooms([])
    }
}
</script>
